<template>
  <div>
    <div class="row pt-5">
      <div class="col-xxl-9">
        <h5 class="font-weight-bold text-dark">
          MwSt. (Nr. CHE-110.292.862 MWST)
        </h5>
      </div>
      <div class="col-xxl-3 text-right">
        <h5 class="font-weight-bold text-dark">
          MwSt. CHF
        </h5>
      </div>
    </div>
    <div class="border-bottom mb-5" />
    <invoice-summary-line
      :description="'inkl. ' + invoice.invoice_vat_percentage + '% MwSt.'"
      :total-price="formatFloat(invoice.invoice_vat_amount)"
      :is-bold="false"
      :has-bottom-border="false"
    />
  </div>
</template>
<script>
import { utilMixin } from "@/mixins/utilMixin";
import InvoiceSummaryLine from "@/components/Invoice/InvoiceSummaryLine";

export default {
  name: "invoice-vat-tax",
  mixins: [utilMixin],
  components: { InvoiceSummaryLine },
  props: {
    invoice: {
      required: true
    }
  }
};
</script>
