<template>
  <div class="pb-5">
    <h4 class="mb-10 font-weight-bold text-dark">
      Rechnung
    </h4>

    <div class="row">
      <div class="col-xxl-4">
        <SubscriptionPropertyLine
          name="Datum:"
          :property="formatDateTime(invoice.invoice_date)"
        />
        <SubscriptionPropertyLine
          name="Zahlbar ohne Abzug bis am:"
          :property="formatDateTime(invoice.payment_due_date)"
        />
        <SubscriptionPropertyLine
          name="Status:"
          :property="invoice.invoice_status_display"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { utilMixin } from "@/mixins/utilMixin";
import SubscriptionPropertyLine from "@/components/SubscriptionPropertyLine";

export default {
  name: "InvoiceHeader",
  mixins: [utilMixin],
  components: { SubscriptionPropertyLine },
  props: {
    invoice: {
      required: true
    }
  }
};
</script>

<style scoped></style>
