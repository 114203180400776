<template>
  <div class="row pt-5">
    <div class="col-xxl-5">
      <h5 class="font-weight-bold text-dark">
        {{ description }}
      </h5>
    </div>
    <div class="col-xxl-3 text-right">
      <h5 class="font-weight-bold text-dark">
        {{ first }}
      </h5>
    </div>
    <div class="col-xxl-2 text-right">
      <h5 class="font-weight-bold text-dark">
        {{ second }}
      </h5>
    </div>
    <div class="col-xxl-2 text-right">
      <h5 class="font-weight-bold text-dark">
        {{ third }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoiceHeaderLine",
  props: {
    description: {
      default: ""
    },
    first: {
      default: ""
    },
    second: {
      default: ""
    },
    third: {
      default: ""
    }
  }
};
</script>

<style scoped></style>
