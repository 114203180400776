<template>
  <div>
    <div class="row pt-0">
      <div class="col-xxl-8">
        <label :class="{ 'font-weight-bold text-dark': isBold }">
          {{ description }}
        </label>
      </div>
      <div class="col-xxl-4 text-right">
        <label :class="{ 'font-weight-bold text-dark': isBold }">
          {{ totalPrice }}
        </label>
      </div>
    </div>
    <div v-if="hasBottomBorder" class="border-bottom mb-5" />
  </div>
</template>

<script>
export default {
  name: "InvoiceSummaryLine",
  props: {
    description: {
      String,
      required: true
    },
    totalPrice: {
      Number,
      required: true
    },
    isBold: {
      Boolean,
      default: true
    },
    hasBottomBorder: {
      Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
