<template>
  <div class="row pb-0">
    <div class="col-xxl-5">
      <label
        :class="{ 'font-weight-bold text-dark': isDescriptionBold || isBold }"
      >
        {{ description }}
      </label>
    </div>
    <div class="col-xxl-3 text-right">
      <label :class="{ 'font-weight-bold text-dark': isBold }">
        {{ count }}
      </label>
    </div>
    <div class="col-xxl-2 text-right">
      <label :class="{ 'font-weight-bold text-dark': isBold }">
        {{ singlePrice }}
      </label>
    </div>
    <div class="col-xxl-2 text-right">
      <label :class="{ 'font-weight-bold text-dark': isBold }">
        {{ totalPrice }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoicePositionLine",
  props: {
    description: {
      String,
      required: true
    },
    count: {
      Number,
      default: 1
    },
    singlePrice: {
      Number,
      required: true
    },
    totalPrice: {
      Number,
      required: true
    },
    isDescriptionBold: {
      Boolean,
      default: false
    },
    isBold: {
      Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
